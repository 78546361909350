//信用等级
export const creditRatingList = [
  {
    name: "铁牌",
    imgUrl: require("assets/images/credit/v-cutter.png"),
    section: "0～60",
  },
  {
    name: "铜牌",
    imgUrl: require("assets/images/credit/v-copper.png"),
    section: "61～100",
  },
  {
    name: "银牌",
    imgUrl: require("assets/images/credit/v-silver.png"),
    section: "101～200",
  },
  {
    name: "金牌",
    imgUrl: require("assets/images/credit/v-metals.png"),
    section: "201～500",
  },
  {
    name: "钻石",
    imgUrl: require("assets/images/credit/v-diamond.png"),
    section: "501～800",
  },
  {
    name: "王者",
    imgUrl: require("assets/images/credit/v-king.png"),
    section: "801～1000",
  },
];
//任务奖励权益列表
export const assignmentCardList = [
  {
    type: 1, //类型
    name: "30天保护卡",
    explain: "首次完成注册", // 获取条件
    color: "#FF7F00", //背景和文字颜色
    style: {
      backgroundImage:
        "url(" + require("assets/images/credit/defend-card.png") + ")",
      "margin-right": "10px", //右侧间距
    },
  },
  {
    type: 2, //类型
    name: "额外奖励",
    encourage: "5%",
    explain: "完成任务", // 获取条件
    color: "#EC6232", //背景和文字颜色
    style: {
      backgroundImage:
        "url(" + require("assets/images/credit/extra-card.png") + ")",
      "margin-right": "10px", //右侧间距
    },
  },
  {
    type: 3, //类型
    name: "金盾保护卡",
    explain: "完成任务", // 获取条件
    color: "#4A79FF", //背景和文字颜色
    style: {
      backgroundImage:
        "url(" + require("assets/images/credit/protect-card.png") + ")",
      "margin-right": "0px", //右侧间距
    },
  },
  {
    type: 4, //类型
    name: "翻倍卡",
    number: 1,
    explain: "完成任务", // 获取条件
    color: "#595FF6", //背景和文字颜色
    style: {
      backgroundImage:
        "url(" + require("assets/images/credit/double-card.png") + ")",
      "margin-right": "10px", //右侧间距
    },
  },
  {
    type: 5, //类型
    name: "VIP快速审核通道",
    explain: "完成任务", // 获取条件
    color: "#5FC683", //背景和文字颜色
    style: {
      backgroundImage:
        "url(" + require("assets/images/credit/vip-card.png") + ")",
      "margin-right": "10px", //右侧间距
    },
  },
  {
    type: 0,
    style: {
      backgroundImage: "url(" + require("assets/images/credit/wave.png") + ")",
      "margin-right": "0px", //右侧间距
    },
  },
];

//处理任务奖励权益列表

export const arrangeCardList = (data) => {
  const cardlist = [];
  data.type.forEach((element, index) => {
    const cardlistItem = JSON.parse(
      JSON.stringify(
        assignmentCardList.find((object) => object.type === element)
      )
    );
    //赋值边距
    if ((index + 1) % 3 == 0) {
      cardlistItem.style["margin-right"] = "0px";
    } else {
      cardlistItem.style["margin-right"] = "10px";
    }
    //赋值翻倍卡
    if (data.number && element === 4) cardlistItem.number = data.number;
    //赋值额外奖励
    if (data.encourage && element === 2) cardlistItem.encourage = data.encourage;
    cardlist.push(cardlistItem);
  });
  return cardlist;
};
