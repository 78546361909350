<template>
  <div class="card" :style="assignmentCardItem.style">
    <div class="card-title" :style="'background:' + assignmentCardItem.color">
      任务奖励权益
    </div>
    <span class="card-content" v-if="!assignmentCardItem.type"
      >更多精彩敬请期待</span
    >
    <div class="card-main" v-if="assignmentCardItem.type">
      <div class="card-name" :style="'color:' + assignmentCardItem.color">
        {{
          assignmentCardItem.type == 2
            ? assignmentCardItem.name + assignmentCardItem.encourage
            : assignmentCardItem.name
        }}
      </div>
      <img
        v-if="assignmentCardItem.type === 4"
        src="@/assets/images/credit/multiplication.png"
        alt="图标"
      />
      <div
        v-if="assignmentCardItem.type === 4"
        :style="'color:' + assignmentCardItem.color"
      >
        {{ assignmentCardItem.number }}
      </div>
    </div>
    <div
      class="card-explain"
      :style="'color:' + assignmentCardItem.color"
      v-if="assignmentCardItem.type"
    >
      {{ assignmentCardItem.explain }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["assignmentCardItem"],
  created() {
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 317.3px;
  height: 128.9px;
  background: #f9f9f9;
  border-radius: 8px 8px 8px 8px;
  padding: 0;
  text-align: center;
  position: relative;
  margin: 0;
  background-size: 100% 100%;
  cursor: pointer;
  margin-bottom: 10px;
  .card-title {
    width: 124px;
    height: 22px;
    line-height: 22px;
    background: #d3d3d3;
    border-radius: 8px 0px 8px 0px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  .card-content {
    display: inline-block;
    width: 144px;
    height: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #d3d3d3;
    margin-top: 32px;
  }
  .card-main {
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-left: 25px;
    div {
      font-size: 18px;
      font-weight: bold;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
  .card-explain {
    margin-top: 0px;
    font-size: 14.3px;
    text-align: left;
    margin-left: 25px;
  }
}
</style>