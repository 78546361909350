export default {
  methods: {
    navigate(to) {
      if (
        to === this.$route.name ||
        (["/", "homepage"].includes(to) &&
          ["/", "homepage"].includes(this.$route.name))
      )
        return;
      this.$router.push(
        to,
        () => {},
        () => {}
      );
    },
    translate(key) {
      return this.$i18n[key] || key;
    }
  }
};
