<template>
  <div class="messages">
    <CreditSystem
      v-if="page === 1"
      @changeCreditPage="changeCreditPage"
    ></CreditSystem>
    <div v-else class="card recommond-my">
      <div class="recommond-my-top">
        <div class="text-grey-dark title-md text-bold">
          {{ $t("my_credit") }}
          <span class="text-orange title-lg text-bold">{{
            this.account.credit
          }}</span>
        </div>
        <div class="goback" @click="page = 1">返回到上一级</div>
      </div>
      <el-table
        :stripe="true"
        :data="list"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dateline"
          :label="$t('happen_time')"
          width="250"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="quantity"
          :label="$t('credit')"
          width="220"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="type"
          :label="$t('status')"
          width="240"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.type !== '-'"
              :type="getScopType(scope.row.type)"
              effect="dark"
              size="mini"
              >{{ scope.row.type }}</el-tag
            >
            <span v-if="scope.row.type === '-'">-</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="description"
          :label="$t('credit_des')"
          width="280"
        >
        </el-table-column>
      </el-table>
      <div class="mt-20">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="pagination.totalSize"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
      <div class="text-grey-dark text-md text-bold mt-20">
        {{ $t("credit_tip") }}：
      </div>
      <div class="text-grey text-md mt-10">
        {{ $t("credit_tip1")
        }}<el-link @click="navigate('/tasks')">{{ $t("to_task") }}</el-link>
      </div>
      <div class="text-grey text-md mt-5">
        {{ $t("credit_tip2") }}
        <el-link @click="navigate('/center/setting')">{{
          $t("to_full_data")
        }}</el-link>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<style scoped>
.recommond-my-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.goback{
  color: #66b1ff;
  cursor: pointer;
}
</style>
<script>
import { mapGetters } from "vuex";
import commonmixin from "../../../../mixin/common.mixin";
import CreditSystem from "components/credit-system";
export default {
  components: { CreditSystem },
  data() {
    return {
      list: [],
      loading: false,
      page: 1,
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1,
      },
    };
  },
  async created() {
    this.getList();
  },
  mixins: [commonmixin],

  methods: {
    changeCreditPage(v) {
      this.page = v;
    },
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.account.id}/Credits`,
        {
          params: {
            page: v || this.pagination.currentPage,
            size: this.pagination.pageSize,
          },
        }
      );
      this.list = res.data.list;
      this.pagination = res.data.pagebar;
      this.loading = false;
    },
    getScopType(status) {
      switch (status) {
        case this.$t("deduct"):
          return "danger";
        case this.$t("add"):
          return "success";
        default:
          return "info";
      }
    },
  },
  computed: {
    ...mapGetters(["account"]),
  },
};
</script>
