<template>
  <div class="content">
    <div class="content-top">
      <div class="content-left">
        <div class="user-info">
          <img class="avatarUrl" :src="account.avatarUrl" :alt="$t('avatar')" />
          <div class="name-honour">
            <div class="user-name">
              {{ userInfo.name || account.userName }}
            </div>
            <div class="user-honour">
              <div class="v-grade">
                <img :src="gradeImg" alt="等级" />
                <span>等级</span>
              </div>
              <img class="medal" :src="gradeMedal" alt="奖牌" />
            </div>
          </div>
        </div>
        <div class="box-credit">
          <div class="box-item box-item-credit" @click="changePage">
            <div>
              <span>{{ account.credit }}</span>
              <img src="@/assets/images/credit/arrowhead.png" />
            </div>
            <div class="box-item-name">信用值</div>
          </div>
          <div class="box-item">
            <div>
              <img src="@/assets/images/credit/add.png" />
              <span>{{ incomePromote }}%</span>
            </div>
            <div class="box-item-name">收益提升</div>
          </div>
          <div class="progress" ref="progress">
            <div class="progress-icon">
              <el-progress
                :percentage="percentage"
                :show-text="false"
                :stroke-width="8"
              ></el-progress>
              <img
                ref="fiveStar"
                class="five-star"
                src="@/assets/images/credit/five-star.png"
                alt="五角星"
              />
            </div>
            <div class="progress-grade">
              <span>v{{ grade }}</span>
              <span>v{{ grade + 1 }}</span>
            </div>
            <div class="progress-gap">
              <div>距离下一等级还差</div>
              <div class="number">
                {{ this.account.credit }}/{{ behindNumer }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right" @click="changePage">
        <div style="width: 0px; height: 0px">
          <svg width="100%" height="100%">
            <defs>
              <linearGradient id="write" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop
                  offset="0%"
                  style="stop-color: #ff6200"
                  stop-opacity="0.8"
                ></stop>
                // offset 设置起始 stop-color 设置起始位置的颜色
                <stop
                  offset="100%"
                  style="stop-color: #ffa200"
                  stop-opacity="1"
                ></stop>
                // offset 设置起始 stop-color 设置起始位置的颜色
              </linearGradient>
            </defs>
          </svg>
        </div>
        <el-progress
          type="circle"
          class="circle"
          :stroke-width="13"
          :percentage="percentageCircle"
          :width="180"
        ></el-progress>
        <div class="circle-text">
          <div>信用值</div>
          <div class="circle-number">{{ account.credit }}</div>
          <div class="circle-grade">{{ gradeName }}</div>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="title">信用值分级说明</div>
      <div class="classification">
        <div
          v-for="(item, index) in creditRatingList"
          :key="index"
          class="classification-item"
          @click="changeRatingItem(index)"
        >
          <span
            :class="
              beforeOneIndex === creditRatingIndex
                ? 'current-level current-active'
                : 'current-level'
            "
          >
            {{ beforeOneIndex === index ? "当前等级" : "" }}
          </span>
          <img :src="item.imgUrl" alt="图标" />
          <span class="name">{{ item.name }}</span>
          <span
            :class="creditRatingIndex === index ? 'section active' : 'section'"
            >{{ item.section }}</span
          >
        </div>
      </div>
      <span :class="`border active${creditRatingIndex}`"></span>
      <div class="credit-cards">
        <CreditCard
          v-for="(item, index) in assignmentCardList"
          :key="index"
          :assignmentCardItem="item"
        ></CreditCard>
      </div>
    </div>
    <div class="content-bottom">
      <div>信用值累计规则</div>
      <p>1.一般： ≤60时，为铁牌会员，可拥有30天保护卡；</p>
      <p>
        2.中等： 61≤信用值≤100，为铜牌会员，可拥有额外奖励卡5%、1张金盾保护卡；
      </p>
      <p>
        3.良好：
        101≤信用值≤200，为银牌会员，可拥有额外奖励卡10%、1张金盾保护卡、VIP快速审核通道；
      </p>
      <p>
        4.优秀：
        201≤信用值≤500，为金牌会员，可拥有额外奖励卡15%、1张金盾保护卡、VIP快速审核通道；
      </p>
      <p>
        5.钻石：
        501≤信用值≤800，为钻石会员，可拥有额外奖励卡20%、1张金盾保护卡、VIP快速审核通道；
      </p>
      <p>
        6.王者：
        801≤信用值≤1000，为王者会员，可拥有额外奖励卡40%、1张金盾保护卡、VIP快速审核通道；
      </p>
      <div class="remarks-title">备注</div>
      <p class="remarks-content">
        1.连续6个月内未参与任何问卷，信用值将会清零。
      </p>
      <p class="remarks-content">
        2.翻倍奖励卡奖励发放规则，每月5日系统会根据您当前的信用值计算出翻倍卡数量，对您上月完成且审核通过的项目随机进行翻倍。特别说明：假设当前信用值大于801，获得4张翻倍卡，但上个月只完成且审核通过2个商业问卷，那么只能翻倍2个商业问卷奖励，剩余的2个翻倍卡失效。活动日期（2023年5月1日至2023年9月30日）
      </p>
      <p class="remarks-content">
        3.金盾保护卡用途，信用值大于60初始值后，对于答卷审核不通过的问卷不再扣除金币。
      </p>
      <p class="remarks-content">
        4.VIP快速审核通道卡只针对兑换微信红包订单，支付宝订单保持每周五统一审核发放订单。
      </p>
      <p class="remarks-content">5.信用值体系会根据实际情况进行调整。</p>
      <p class="remarks-content">
        6.福利卡包含“翻倍卡”、“増值卡”、“保护卡”
        、“审核卡”、“抽奖卡”。具体发放的卡劵及对应数量，与当时信用值有关，以实际发放时为准。
      </p>
      <p class="remarks-content">
        7.通过非正当手段获取信用值者，将受到惩罚，严重者将被删除账号。
      </p>
      <p class="remarks-content">
        8.目前只针对商业调查进行信用值审核，K问卷，Y问卷，小幸运问卷，G问卷，S问卷除外。
      </p>
      <p class="remarks-content">
        9.信用值低于初始值60以后，支付宝和微信兑换订单将进入人工答卷内容审核阶段，审核答卷完成后进行订单审核，一般时间1-4周时间范围。
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  creditRatingList,
  assignmentCardList,
  arrangeCardList
} from "utlis/creditRating.js";
import CreditCard from "./credit-card/index.vue";
export default {
  components: { CreditCard },
  data() {
    return {
      percentage: 0,
      percentageCircle: 0,
      creditRatingList: [],
      assignmentCardList: [],
      allAssignmentCardList: [],
      creditRatingIndex: "",
      beforeOneIndex: "",
      gradeImg: "",
      gradeMedal: "",
      gradeName: "",
      frontNumber: "",
      behindNumer: "",
      incomePromote: ""
    };
  },
  computed: {
    ...mapGetters(["account", "userInfo"]),
    grade() {
      if (this.account.credit <= 60) {
        return 1;
      }
      if (61 <= this.account.credit && this.account.credit <= 100) {
        return 2;
      }
      if (101 <= this.account.credit && this.account.credit <= 200) {
        return 3;
      }
      if (201 <= this.account.credit && this.account.credit <= 500) {
        return 4;
      }
      if (501 <= this.account.credit && this.account.credit <= 800) {
        return 5;
      }
      if (801 <= this.account.credit && this.account.credit <= 1000) {
        return 6;
      }
      return 1;
    }
  },
  methods: {
    //计算环形信用值
    changePercentage() {
      this.percentageCircle = (this.account.credit * 100) / 1000;
    },
    changeRatingItem(index) {
      this.creditRatingIndex = index;
      this.assignmentCardList = [];

      this.arrangeCardList(index);
    },
    changePage() {
      this.$emit("changeCreditPage", 2);
    },

    //处理卡片数组
    arrangeCardList(index) {
      switch (index) {
        case 0:
          this.assignmentCardList = arrangeCardList({
            type: [1]
          });
          break;
        case 1:
          this.assignmentCardList = arrangeCardList({
            type: [2, 3],
            encourage: "5%"
          });
          break;
        case 2:
          this.assignmentCardList = arrangeCardList({
            type: [2, 3, 5],
            number: 1,
            encourage: "10%"
          });
          break;
        case 3:
          this.assignmentCardList = arrangeCardList({
            type: [2, 3, 5],
            number: 2,
            encourage: "15%"
          });
          break;
        case 4:
          this.assignmentCardList = arrangeCardList({
            type: [2, 3, 5],
            number: 3,
            encourage: "20%"
          });
          break;
        case 5:
          this.assignmentCardList = arrangeCardList({
            type: [2, 3, 5],
            number: 4,
            encourage: "40%"
          });
          break;
      }
    }
  },
  mounted() {
    // 赋值
    this.creditRatingList = creditRatingList;
    this.allAssignmentCardList = assignmentCardList;
    // 赋值index
    this.creditRatingIndex = this.grade - 1;
    this.beforeOneIndex = this.creditRatingIndex;
    this.arrangeCardList(this.creditRatingIndex);
    this.gradeImg = require(`@/assets/images/credit/v${this.grade}.png`);
    this.changePercentage();
    console.log(11111, this.grade);
    switch (this.grade) {
      case 1:
        this.gradeMedal = require(`@/assets/images/credit/v-cutter.png`);
        this.gradeName = "一般";
        this.frontNumber = 0;
        this.behindNumer = 60;
        this.incomePromote = 0;
        break;
      case 2:
        this.gradeMedal = require(`@/assets/images/credit/v-copper.png`);
        this.gradeName = "中等";
        this.frontNumber = 61;
        this.behindNumer = 100;
        this.incomePromote = 5;
        break;
      case 3:
        this.gradeMedal = require(`@/assets/images/credit/v-silver.png`);
        this.gradeName = "良好";
        this.frontNumber = 101;
        this.behindNumer = 200;
        this.incomePromote = 10;
        break;
      case 4:
        this.gradeMedal = require(`@/assets/images/credit/v-metals.png`);
        this.gradeName = "优秀";
        this.frontNumber = 201;
        this.behindNumer = 500;
        this.incomePromote = 15;
        break;
      case 5:
        this.gradeMedal = require(`@/assets/images/credit/v-diamond.png`);
        this.gradeName = "钻石";
        this.frontNumber = 501;
        this.behindNumer = 800;
        this.incomePromote = 20;
        break;
      case 6:
        this.gradeMedal = require(`@/assets/images/credit/v-king.png`);
        this.gradeName = "王者";
        this.frontNumber = 801;
        this.behindNumer = 1000;
        this.incomePromote = 40;
        break;
    }

    // 计算进度条位置
    this.percentage =
      ((this.account.credit - this.frontNumber) * 100) /
      (this.behindNumer - this.frontNumber);
    const left = (this.$refs.progress.clientWidth * this.percentage) / 100 - 10;
    document.getElementsByClassName("five-star")[0].style.left = `${left}px`;
  }
};
</script>
<style scoped>
.circle >>> svg > path:nth-child(2) {
  stroke: url(#write);
}
.circle >>> .el-progress__text {
  display: none;
}
.progress >>> .el-progress-bar__inner {
  background: linear-gradient(90deg, #ffa200 0%, #ff6c00 100%);
}
</style>
<style lang="scss" scoped>
.content {
  width: 1032px;
  .content-top {
    width: 100%;
    height: 280px;
    display: flex;
    .content-left {
      width: 670px;
      height: 100%;
      background-color: #fff;
      margin-right: 12px;
      .user-info {
        display: flex;
        .avatarUrl {
          width: 59px;
          height: 59px;
          background: #d2d8e0;
          border-radius: 33px;
          text-align: center;
          line-height: 59px;
          margin-top: 30px;
          margin-left: 30px;
        }
        .name-honour {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          .user-name {
            font-size: 14px;
            font-weight: bold;
            color: #232223;
            margin-top: 35px;
          }
          .user-honour {
            margin-top: 10px;
            display: flex;
            align-items: center;
            .v-grade {
              width: 55px;
              height: 21px;
              background: #fff6e5;
              border-radius: 21px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
              span {
                color: #ea7e30;
                font-size: 12px;
                margin-right: 6px;
              }
            }
            .medal {
              width: 25px;
              height: 25px;
              margin-left: 8px;
            }
          }
        }
      }
      .box-credit {
        width: calc(100% - 60px);
        height: 150px;
        background: #fff6e5;
        border-radius: 10px;
        margin-left: 30px;
        margin-top: 18px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .box-item-credit {
          cursor: pointer;
        }
        .box-item {
          span {
            font-size: 42px;
            color: #ffa200;
          }
          img {
            width: 10px;
            height: 10px;
          }
          .box-item-name {
            margin-top: 8px;
            font-size: 14px;
            color: #333;
            letter-spacing: 1.5px;
            text-align: center;
          }
        }
        .progress {
          width: 200px;
          margin-top: 30px;
          .progress-icon {
            position: relative;
            width: 100%;
            .five-star {
              position: absolute;
              top: -4px;
              width: 16px;
              height: 16px;
            }
          }
          .progress-grade {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            font-size: 12px;
            font-weight: bold;
            color: #232323;
          }
          .progress-gap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            color: #333333;
            margin-top: 10px;
            .number {
              font-size: 16px;
              font-weight: bold;
              color: #ffa200;
            }
          }
        }
      }
    }
    .content-right {
      width: 350px;
      height: 100%;
      border-radius: 10px;
      background-color: #fff;
      position: relative;
      cursor: pointer;
      .circle {
        margin: 50px 85px;
      }
      .circle-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: #666666;
        text-align: center;
        .circle-number {
          font-size: 42px;
          font-weight: bold;
          color: #ffa200;
        }
        .circle-grade {
          color: #333;
        }
      }
    }
  }
  .content-main {
    width: 100%;
    background-color: #fff;
    margin: 12px 0;
    padding: 30px;
    .title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .classification {
      display: flex;
      justify-content: space-between;
      .classification-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
        .name {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-top: 12px;
        }
        .section {
          font-size: 16px;
          font-weight: 400;
          color: #232323;
          margin-top: 20px;
          font-size: 16px;
        }
        .active {
          font-weight: bold;
          color: #ffa200;
        }
        .current-level {
          display: inline-block;
          width: 56px;
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-bottom: 12px;
        }
        .current-active {
          color: #ffa200;
        }
      }
    }

    .border {
      display: inline-block;
      width: 40px;
      height: 6px;
      background-color: #ffa200;
      border-radius: 6px;
      margin-top: 8px;
      transform: translateX(50px) translateX(-50%);
      transition: 0.3s;
    }
    .active0 {
      transform: translateX(50px) translateX(-50%);
    }
    .active1 {
      transform: translateX(224.4px) translateX(-50%);
    }
    .active2 {
      transform: translateX(398.8px) translateX(-50%);
    }
    .active3 {
      transform: translateX(573.2px) translateX(-50%);
    }
    .active4 {
      transform: translateX(747.6px) translateX(-50%);
    }
    .active5 {
      transform: translateX(922px) translateX(-50%);
    }
  }
  .content-bottom {
    width: 100%;
    padding: 30px;
    min-height: 248px;
    background-color: #fff;
    margin: 12px 0;
    div {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .remarks-title {
      margin: 24px 0 8px 0;
      color: #999;
      font-weight: normal;
      font-size: 13px;
    }
    .remarks-content {
      color: #999;
      font-size: 13px;
      margin-bottom: 8px;
    }
  }
}
.credit-cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
</style>
